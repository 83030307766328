import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import BrandLogo from 'src/assets/images/logo.svg';
import BrandLogoNarrow from 'src/assets/images/logo-narrow.svg';
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import { toggleSidebar } from 'src/store';

const AppSidebar = () => {
  const dispatch = useDispatch()
  const {sidebarShow, sidebarUnfoldable} = useSelector(({common}) => common)

  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(toggleSidebar({ sidebarShow: visible }))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img className='sidebar-brand-full' src={BrandLogo} />
        <img className='sidebar-brand-narrow' src={BrandLogoNarrow} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(toggleSidebar({ sidebarUnfoldable: !sidebarUnfoldable }))}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
