import React, { useEffect, useState } from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CRow,
} from '@coreui/react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import OTPInput from 'src/components/OTPInput'
import clientApi from 'src/network'
import { OTP_VERIFY_SUCCESS } from 'src/Constant'
import { toast } from 'react-toastify'
import ResendOTPButton from './ResendOTPButton'


const OTPVerification = () => {
    const location = useLocation();
    const {email} = location?.state || {};
    if(!email){
        return <Navigate to={"/"} />;
    }
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [currentIndex, setCurrentIndex] = useState(0)
    const [finalOtp, setFinalOtp] = useState('');

    useEffect(() => {
        setFinalOtp(otp?.join(''))
    }, [otp])

    const handleOtpChange = (index, value) => {
        if(index >= 4 || value?.length > 1)
        return;
        const updatedOtp = [...otp];
        updatedOtp[index] = value;
        setOtp(updatedOtp);
        setCurrentIndex(++index)
    };

    const handleBackspace = (index) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = '';
        setOtp(updatedOtp);
        setCurrentIndex(index)
    };

    const handleVerifyClick = async (e) => {
        e.preventDefault()
        try {
            const { data } = await clientApi.patch("/auth/verify-otp", {email, otp: finalOtp});
            if (data) {
            toast.success(OTP_VERIFY_SUCCESS);
              setTimeout(
                () =>
                  navigate("/reset-password", {
                    state: {otp: finalOtp},
                    replace: true,
                  }),
                3000
              );
            }
        } catch (err) {
            console.log("err", err);
        }
    };

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                <CCol md={8}>
                    <CCardGroup>
                    <CCard className="p-4">
                        <CCardBody>
                        <CForm method='post' onSubmit={handleVerifyClick}>
                            <h1>Check your email</h1>
                            <p className="text-medium-emphasis">We sent a four-digit code to {email}. Enter the code to reset your password</p>
                            <CRow className='mb-4'>
                                <div className="otp-input" style={{display: 'flex'}}>
                                {otp.map((val, i) => <CCol key={`otpinput-${i}`} sm='auto' style={{display: 'flex'}}>
                                    <OTPInput otpDigits={otp} onDigitChange={ handleOtpChange } onBackspace={handleBackspace} index={i} autoFocus={i === currentIndex} /></CCol>)}
                                </div>
                            </CRow>
                            <CRow>
                            <CCol xs={6}>
                                <CButton type='submit' color="primary" className="px-4" disabled={finalOtp.length === 4 ? false : true}>
                                Verify
                                </CButton>
                            </CCol>
                            <CCol xs={6} className="text-right">
                                <CButton color="link" className="px-0">
                                    <Link to={'/'}>
                                        Back to Login
                                    </Link>
                                </CButton>
                            </CCol>
                            <CCol xs={12} className='my-3'>
                                {email && <ResendOTPButton email={email} />}
                            </CCol>
                            </CRow>
                        </CForm>
                        </CCardBody>
                    </CCard>
                    <CCard className="text-white py-5 sidebar-banner">
                        <CCardBody className="text-center">
                        </CCardBody>
                    </CCard>
                    </CCardGroup>
                </CCol>
                </CRow>
            </CContainer>
            </div>
    )
}

export default OTPVerification