import React from 'react';
import {  CNav, CNavItem, CNavLink } from '@coreui/react';

const Tabs = ({currentTab, changeTab}) => {


  return (<>
      <CNav variant="tabs" className='mb-4'>
        <CNavItem key={`tab-paid-itineray`}>
          <CNavLink  onClick={() => changeTab(true)} active={currentTab ? true : false}>
            Paid Itineraries
          </CNavLink>
        </CNavItem>
        <CNavItem key={`tab-unpaid-itineray`}>
          <CNavLink onClick={() => changeTab(false)} active={!currentTab ? true : false}>
            Unpaid Itineraries
          </CNavLink>
        </CNavItem>
      </CNav>
    </>);
};

export default Tabs