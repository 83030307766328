import React from "react";
import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CBadge } from "@coreui/react";
import PropTypes from "prop-types";
import List from "./List";
const BadgeArry = { Fixed: "primary", Optional: "info", Multiselect: "warning" };
const QuizAccordion = ({ quizItem, isBadge }) => {
  return (
    <CAccordion>
      {quizItem?.map((quiz, i) => (
        <CAccordionItem itemKey={i} key={`quiz-${i}`}>
          <CAccordionHeader>
            <div className="custom-accordion">
              <div className="fw-bold me-auto custom-accordion__item">{quiz?.label}</div>
              <div>            
                {isBadge && (
                <CBadge color={BadgeArry[quiz?.type]} shape="rounded-pill">
                  {quiz?.type}
                </CBadge>
                )}
              </div>
            </div>
          </CAccordionHeader>
          <CAccordionBody>
            <List optionList={quiz?.options} />
          </CAccordionBody>
        </CAccordionItem>
      ))}
    </CAccordion>
  );
};

export default QuizAccordion;
QuizAccordion.propTypes = {
  quizItem: PropTypes.array,
  isBadge: PropTypes.bool,
};
