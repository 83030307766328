import React, { useState } from 'react'
import { CCardImage } from '@coreui/react'
import imgPlaceHolder from '../../assets/images/imgPlaceholder.gif'
import { reduceImageQuality } from 'src/utils/common';

const ImageLoader = ({url}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageError, setImageError] = useState(false);

    const imageStyle = !imageLoaded ? { display: "none" } : {};
    const handleImageLoad = () => {
        setImageLoaded(true);
    };
    const handleImageError = () => {
        setImageError(true);
    };

    return (
    <div className='itinerary-card__img'>
        {!imageLoaded && !imageError && (
          <div className='card-placeholder' style={{ backgroundImage: `url(${imgPlaceHolder})`}}></div>
      )}
        <CCardImage
          style={imageStyle}
          src={reduceImageQuality(url, 600, 75)}
          alt="Actual Image"
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
        
    </div>)
}

export default ImageLoader