import React from "react";
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CRow,
} from "@coreui/react";
import { getPlanDate } from "src/utils/common";
import ImageLoader from "./ImageLoader";
import TeamMetes from "./TeamMetes";
import { Link } from "react-router-dom";

const Card = ({ data }) => {
  const { itinerary, tripMatesDetails, _id } = data;
  const itineraryImg = itinerary?.itinerary?.[0]?.image?.url;
  const itineraryDate = data?.createdAt ? getPlanDate(data?.createdAt) : null;
  return (
    <>
      {itinerary && (
        <CCol sm={4} className="mb-3">
          <CCard className="itinerary-card">
            <Link to={`details?id=${_id}`}>
              <div className="tripDay">
                <h5>{itinerary?.days} Day Trip</h5>
              </div>
              <ImageLoader url={itineraryImg} />
              <CCardBody>
                <CRow>
                  <CCol sm={9}>
                    <CCardTitle title={itinerary?.destination}>
                      {itinerary?.destination}
                    </CCardTitle>
                    <CCardText>{itineraryDate}</CCardText>
                  </CCol>
                  <CCol sm={3}>
                    <div className="user-icon">
                      {tripMatesDetails && (
                        <TeamMetes data={tripMatesDetails} />
                      )}
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </Link>
          </CCard>
        </CCol>
      )}
    </>
  );
};

export default Card;
