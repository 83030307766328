import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  AccommodationIcon,
  ActivityIcon,
  RestaurantsIcon,
} from "src/assets/images";
import { TEXT } from "src/Constant";
import {
  capitalizeFirstLetter,
  convertToBase64,
} from "../../utils/utilityFunctions";
import { appendQueryParametersInImage } from "../../utils/utilityFunctions";

const Singleitinerary = ({ ClassName, item, status, paymentLink }) => {
  const [base64Image, setBase64Image] = useState(null);

  useEffect(() => {
    if (item?.image?.url) {
      const url = item?.image?.url;
      const updatedUrl = appendQueryParametersInImage(url);
      convertToBase64(updatedUrl)
        .then((updatedUrl) => {
          setBase64Image(updatedUrl);
        })
        .catch((error) => {
          console.error("Error converting image to base64:", error);
        });
    }
  }, [item]);

  const journeyHandler = () => {
    if (paymentLink) {
      window.open(paymentLink, "_self");
    }
  };

  const handleLinkClick = (status, url) => {
    if (status) {
      window.open(url, "_blank");
    }
  };

  let tripAdvisor =
    Array?.isArray(item?.trip_advisor_suggestions) &&
    item?.trip_advisor_suggestions?.length > 0
      ? [...item?.trip_advisor_suggestions]
      : [];

  if (!status) {
    tripAdvisor = [
      { category: "accommodation" },
      { category: "restaurants" },
      { category: "activities" },
    ];
  }
  return (
    <div>
      <Row>
        <Col>
          <div className={`tr-single-itinerary-box ${ClassName}`}>
            <div className="tr-iti-img-wrap">
              <img
                src={base64Image}
                alt="Loading.."
                className="itinerary-hero-img"
              />

              <span className="tr-iti-day">{item?.title}</span>
            </div>
            <div className="tr-iti-content-wrap">
              <span className="tr-iti-title">{item?.title}</span>
              <span className="tr-iti-desc">{item?.description}</span>
              <Row>
                <Col xl="8" className="trip-advisor-paid-grp">
                  {tripAdvisor?.map((data, index) => {
                    let linkText = "";
                    let icons = "";
                    let color = "";
                    if (data?.category === "accommodation") {
                      linkText = TEXT.BOOK_STAY;
                      icons = AccommodationIcon;
                      color = "#DD2590";
                    } else if (data?.category === "restaurants") {
                      linkText = TEXT.BOOK_TABLE;
                      icons = RestaurantsIcon;
                      color = "#5720B7";
                    } else if (data?.category === "activities") {
                      linkText = TEXT.BOOK_ACTIVITY;
                      icons = ActivityIcon;
                      color = "#DC6803";
                    }
                    return (
                      <div
                        key={`trip_advisor_suggestions-${index}`}
                        className={`trip-advisor-item ${
                          status ? "trip_advisor_paid" : "trip_advisor_unpaid"
                        }`}
                      >
                        <div className="d-flex">
                          <div>
                            <img src={icons} alt="" />
                          </div>

                          <h3>{capitalizeFirstLetter(data?.category)}</h3>
                        </div>
                        <p
                          style={{ color }}
                          onClick={() => handleLinkClick(status, data?.web_url)}
                        >
                          {linkText}
                        </p>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Singleitinerary;
