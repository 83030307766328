import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CButton, CCol, CFormInput, CFormSelect, CInputGroup, CInputGroupText, CRow } from "@coreui/react";
import { cilSearch, cilCalendar, cilArrowThickToBottom } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Filter = ({ filterVal, setFilterVal, ExportUserEmails, setCurrentPage }) => {

  const HandleSearchBox = (e) => {
    setCurrentPage(1)
    setFilterVal({...filterVal , q : e.target.value, isFilter: true, page: 1 })
  };

  const HandleDateRangePicker = (update) => {
    setCurrentPage(1)
    setFilterVal({ ...filterVal, startDate: update?.[0], endDate: update?.[1], isFilter: true, page: 1 });
  };

  const HandleGeneratedPlans = (e) => {
    const [planCount, operation] = e?.target?.value?.split('_');
    setCurrentPage(1)
    setFilterVal({ ...filterVal, planCount, operation, isFilter: true, page: 1 })
  }

  
  return (
    <CRow className="mb-4">
      <CCol xs={3}>
        <CInputGroup>
          <CFormInput type="text" placeholder="Search" size="sm" onChange={HandleSearchBox} value={filterVal?.q} />
          <CInputGroupText>
            <CIcon icon={cilSearch} />
          </CInputGroupText>
        </CInputGroup>
      </CCol>
      <CCol xs={4}>
        <CInputGroup>
          <DatePicker
            selectsRange={true}
            startDate={filterVal?.startDate}
            endDate={filterVal?.endDate}
            className="form-control form-control-sm"
            onChange={HandleDateRangePicker}
            dateFormat="dd/MM/yyyy"
            placeholderText="Date"
          />
          <CInputGroupText>
            <CIcon icon={cilCalendar} />
          </CInputGroupText>
        </CInputGroup>
      </CCol>
      <CCol xs={2}>
        <CFormSelect size="sm" value={`${filterVal?.planCount === 'all' ? 'all' : filterVal?.planCount+'_'+filterVal?.operation}`} onChange={HandleGeneratedPlans}>
          <option value="1_equals">{`1`}</option>
          <option value="2-3_range">{`2 - 3`}</option>
          <option value="4-5_range">{`4 - 5`}</option>
          <option value="5_greaterThan">{`> 5`}</option>
          <option value="all">All</option>
        </CFormSelect>
      </CCol>
      <CCol xs={3} className="text-end">
        <CButton color="primary" onClick={ExportUserEmails}>
          Download Emails <CIcon icon={cilArrowThickToBottom} />
        </CButton>
      </CCol>
    </CRow>
  );
};

export default memo(Filter);

Filter.propTypes = {
  setFilterVal: PropTypes.func,
  setCurrentPage: PropTypes.func,
  ExportUserEmails: PropTypes.func,
  filterVal: PropTypes.any,
};
