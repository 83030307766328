import styled from "styled-components";

export const LoaderStyledWrapper = styled.div`
    padding: 80px 0;
    .spinner-border {
        border: 2px solid ${({ theme }) => theme?.colors?.border?.bdrDarkClr};
        height:40px;
        width:40px;
    }
    .text-secondary {
        display:none;
    }
`;
