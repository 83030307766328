import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { ChevLeft, NextIconBlue, PrevIconBlue } from "../../assets/icons";
import { convertToBase64 } from "../../utils/utilityFunctions";
import { appendQueryParametersInImage } from "../../utils/utilityFunctions";
import './style.scss';
import { useLocation } from "react-router-dom";
import { BREADCRUMBARRAY } from "src/Constant";

const Itineraryslider = ({ item }) => {
  // const currentLocation = useLocation()?.pathname
  // const r = currentLocation.split('/');
  // const curretRoute =  r[r?.length - 1]

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "30px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    nextArrow: <NextIconBlue />,
    prevArrow: <PrevIconBlue />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          autoplay: true,
        },
      },
    ],
  };

  const [imageUrls, setImageUrls] = useState([]);
  useEffect(() => {
    // BREADCRUMBARRAY[0]['details']['name'] = item && `${item?.personality} in ${item?.itinerary?.destination}`;
    if (item?.itinerary?.itinerary) {
      const imageURLs = item?.itinerary?.itinerary.map((element) => {
        const url = element?.image?.url;
        return appendQueryParametersInImage(url);
      });
      Promise.all(imageURLs.map((url) => convertToBase64(url)))
        .then((base64Images) => {
          const limitedImages = base64Images.slice(0, 5);
          setImageUrls(limitedImages);
        })
        .catch((error) => {
          console.error("Error converting images to base64:", error);
        });
    }
  }, [item]);

  return (
      <div>
      <div className="tr-slider-wrapper">
        <Container>
          <Row>
            <Col>
              <div className="tr-slider-info-area">
                <div className="tr-slider-back-mob">
                  <ChevLeft />
                </div>
                <span className="tr-slider-title">
                {["a", "e", "i", "o", "u"].includes(	
                    item?.personality?.[0]?.toLowerCase()	
                  )	
                    ? "An"	
                    : "A"}{" "}{item?.personality} in {item?.itinerary?.destination}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
        {item?.isPaid ? (
          <>
            {imageUrls.length === 1 ? (
              <div className="container my-5">
                <div className="slider-img-box slider-img-box--mob">
                  <img
                    src={imageUrls[0]}
                    alt="Loading.."
                    width={"100%"}
                    className="img-fluid"
                  />
                </div>
              </div>
            ) : (
              <div className="tr-itinerary-slider-box">
                <Container>
                  <Row>
                    <Col>
                      <div className="tr-itinerary-slider-box-inner">
                        <Slider {...settings}>
                          {imageUrls?.map((base64Image, index) => (
                            <div key={index}>
                              <div className="slider-img-box">
                                <img
                                  src={base64Image}
                                  alt={`Hero ${index + 1}`}
                                />
                              </div>
                              <div className="slider-img-box slider-img-box--mob">
                                <img src={base64Image} alt="Loading.." />
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          </>
        ) : (
          <div className="container my-5">
            <div className="slider-img-box slider-img-box--mob">
              <img
                src={imageUrls[0]}
                alt="Loading.."
                width={"100%"}
                className="img-fluid"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Itineraryslider;
