import React, { useEffect, useState } from "react";
import Itineraryslider from "./slider";
import ItineraryOverview from "./overview";
import Singleitinerary from "./singleitinerary";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "../../components/loader";
import { TEXT } from "../../Constant";
import  UserIconPlace  from "../../assets/images/profile-image.jpg";
import Image from "../../components/image";
import clientApi from "src/network";
import './style.scss';

const ItineraryLayout = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // const { getItineraryDetailsById } = useItinerary();
  const [loading, setLoading] = useState(false);
  const [itineraryResponse, setItineraryResponse] = useState();
  const id = searchParams.get("id") || null;

  useEffect(() => {
    PlanDetailAction()
  }, [id])

const PlanDetailAction = async () => {
  try {
      setLoading(true);
      const { data } = await clientApi.get("/itineraries/"+id);
      setItineraryResponse(data?.responseData)
      setLoading(false);
    } catch (err) {
      console.log("err", err);
    }
};



  if (loading) {
    return (
      <div className="web-spinner-loader">
        <Loader size="lg" dimension="row" message={TEXT?.ON_LOAD_ITINERARY} />
      </div>
    );
  }
  if (!location.state && !location.search && !itineraryResponse) {
    return (
      <div>
        <Container>
          <h1 className="text-secondary text-center mt-5">No data found</h1>
        </Container>
      </div>
    );
  }
  return (
    <div>
      <div id="pdf-content">
        <Container>
          <Row>
            <Col>
              <div>
                <Itineraryslider item={itineraryResponse} />
              </div>
              <Container>
              <div>
                  <Row className="tr-itinerary-inprogress-wrap ">
                    {itineraryResponse?.tripMatesDetails?.map((item, index) => (
                      <Col lg={4} md={6} xs={12} key={`${index}-tripmate`} className="mb-5">
                        <div className="tr-itinerary-inprogress-card">
                          <span
                            className={`tr-label ${
                              item.status === "Finished"
                                ? "tr-label-finished"
                                : "tr-label-sent"
                            }`}
                          >
                            {item?.status === "Finished"
                              ? "Finished"
                              : "In Progess"}
                          </span>
                          <div className="tr-user-info-area">
                            <div className="tr-user-img" title={item?.email}>
                              <Image
                                src={
                                  item?.picture ? item?.picture : UserIconPlace
                                }
                                alt=""
                                width={"64px"}
                              />
                            </div>
                            <div className="tr-user-info">
                              <span className="tr-username">
                                {item?.firstName} {item.lastName}
                              </span>
                              <span className="tr-user-location">
                                {itineraryResponse?.itinerary?.destination}
                              </span>
                            </div>
                          </div>
                          <div className="tr-user-desc">
                            {item?.personality_description}
                          </div>
                        </div>
                      </Col>
                    ))}
                    <ItineraryOverview item={itineraryResponse} />
                    {itineraryResponse?.isPaid &&
                      itineraryResponse?.itinerary?.itinerary?.map(
                        (item, index) => (
                          <Singleitinerary
                            ClassName="tr-itinerary-active"
                            item={item}
                            status={itineraryResponse?.isPaid}
                            key={`${index}-itinerary-paid`}
                          />
                        )
                      )}
                    {!itineraryResponse?.isPaid &&
                      itineraryResponse?.itinerary?.itinerary?.map(
                        (item, index) => (
                          <>
                            {!itineraryResponse?.isPaid && index === 0 && <Singleitinerary
                              ClassName={
                                index === 0
                                  ? "tr-itinerary-active"
                                  : "tr-itinerary-blur"
                              }
                              item={item}
                              status={itineraryResponse?.isPaid}
                              inviteLink={itineraryResponse?.inviteLink}
                              paymentLink={itineraryResponse?.paymentLink}
                              key={`${index}-itinerary-unpaid`}
                              userId={itineraryResponse?.userId}
                            /> }
                          </>
                        )
                      )}
                    <div>
                      <Col>
                        <div className="tr-card-grp">
                          {itineraryResponse?.itinerary?.hidden_gems && (
                            <div className="tr-card-grp-item">
                              <div className="tr-card-item">Hidden Gem</div>
                              <div className="tr-user-desc">
                                {itineraryResponse?.itinerary?.hidden_gems}
                              </div>
                            </div>
                          )}
                          {itineraryResponse?.itinerary?.helpful_tips && (
                            <div className="tr-card-grp-item">
                              <div className="tr-card-item">Helpful Tips</div>
                              <div>
                                <ul>
                                  {itineraryResponse?.itinerary?.helpful_tips?.map(
                                    (tip, index) => (
                                      <li key={index} className="tr-user-desc">
                                        {tip}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ItineraryLayout;
