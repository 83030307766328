import React from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormFeedback,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
  } from "@coreui/react";
  import CIcon from "@coreui/icons-react";
  import { cilUser, cilLockLocked } from "@coreui/icons";
import { useFormik } from 'formik';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import clientApi from 'src/network';
import * as Yup from "yup";
import { PASSWORD_RESET_SUCCESS } from 'src/Constant';
import { toast } from 'react-toastify';


const validationSchema = Yup.object({
    newPassword: Yup.string()
    .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'Password must meet the following criteria:'
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required'),
});

const ResetPassword = () => {
    const location = useLocation();
    const {otp} = location?.state || {};
    if(!otp){
        return <Navigate to={"/"} />;
    }
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword:""
        },
        validationSchema,
        onSubmit: (values) => {
          const {newPassword} = values;
          const postData = {newPassword, otp}  
          ResetPasswordAction(postData);
        },
    });

    const ResetPasswordAction = async (param) => {
        try {
          const { data } = await clientApi.patch("/auth/forgot-password-reset", param);
          if (data && data?.message === "PASSWORD_CHANGED") {
            toast.success(PASSWORD_RESET_SUCCESS);
            setTimeout(
              () =>
                navigate("/", {replace: true}),
              3000
            );
          }
        } catch (err) {
          console.log("err", err);
        }
    };

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
            <CRow className="justify-content-center">
            <CCol md={8}>
                <CCardGroup>
                <CCard className="p-4">
                    <CCardBody>
                    <CForm method="post" onSubmit={formik.handleSubmit}>
                        <h1 className='mb-5'>Reset Password</h1>
                        {/* <p className="text-medium-emphasis">Retrive your account</p> */}
                        <CInputGroup className="mb-3">
                            <CInputGroupText>
                                <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                                type="password"
                                placeholder="New Password"
                                name="newPassword"
                                id="newPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.newPassword}
                                invalid={formik?.touched?.newPassword && formik?.errors?.newPassword ? true : false}
                            />
                            {formik?.touched?.newPassword && formik?.errors?.newPassword ? 
                            <CFormFeedback invalid>{ (formik?.errors?.newPassword === "Password must meet the following criteria:") ? <>
                                <p className='mb-0'>{formik?.errors?.newPassword}</p>
                                <ul>
                                    <li>Must contain at least one uppercase letter</li>
                                    <li>Must contain at least one special character</li>
                                    <li>Must contain at least one numeric character</li>
                                    <li>Must be at least 8 characters long</li>
                                </ul> </> : formik?.errors?.newPassword}
                            </CFormFeedback> : null}
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                            <CInputGroupText>
                                <CIcon icon={cilLockLocked} />
                            </CInputGroupText>
                            <CFormInput
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                id="confirmPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values?.confirmPassword}
                                invalid={formik?.touched?.confirmPassword && formik?.errors?.confirmPassword ? true : false}
                            />
                            {formik?.touched?.confirmPassword && formik?.errors?.confirmPassword ? <CFormFeedback invalid>{formik?.errors?.confirmPassword}</CFormFeedback> : null}
                        </CInputGroup>
                        <CRow>
                        <CCol xs={6}>
                            <CButton type="submit" color="primary" className="px-4">
                            Submit
                            </CButton>
                        </CCol>
                        {/* <CCol xs={6} className="text-right">
                            <CButton color="link" className="px-0">
                            <Link to={"/"}>Back</Link>
                            </CButton>
                        </CCol> */}
                        </CRow>
                    </CForm>
                    </CCardBody>
                </CCard>
                <CCard className="text-white py-5 sidebar-banner">
                    <CCardBody className="text-center">
    
                    </CCardBody>
                </CCard>
                </CCardGroup>
            </CCol>
            </CRow>
        </CContainer>
        </div>
    )
}

export default ResetPassword