import React from "react";
import { CListGroup, CListGroupItem } from "@coreui/react";
import PropTypes from "prop-types";
const List = ({ optionList }) => {
  return (
    <CListGroup>
      {optionList?.length > 0 ? (
        optionList?.map((opt, i) => (
          <CListGroupItem key={`optionList-${i}`}>
            <strong>
              {++i}
              {"."}
            </strong>{" "}
            {opt?.label}
          </CListGroupItem>
        ))
      ) : (
        <p>User responses will be shown here.</p>
      )}
    </CListGroup>
  );
};

export default List;
List.propTypes = {
  optionList: PropTypes.array,
};
