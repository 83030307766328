import React from "react";
import { AppContent, AppSidebar, AppFooter, AppHeader } from "../components/index";
import { useAuth } from "src/hooks/useAuth";
import { Navigate } from "react-router-dom";

const DefaultLayout = () => {
  const { AuthUser } = useAuth();

  if (!Object.keys(AuthUser).length) {
    return <Navigate to={"/"} />;
  }

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default DefaultLayout;
