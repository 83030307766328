import { CSpinner } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PlansComponent from "src/components/plans";
import Tabs from "src/components/plans/Tabs";
import clientApi from "src/network";

const Plans = () => {
  // const PlansComponent = lazy(() =>
  //   import(
  //     /* webpackChunkName: "users/generated-plans" */ "src/components/plans"
  //   )
  // );
  const [userPlans, setUserPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const userId = location?.state?.userId;
  const [isPaid, setIsPaid] = useState(true);

  useEffect(() => {
    UserPlansAction({ userId, paymentStatus: isPaid ? "paid" : "unpaid" });
  }, [userId, isPaid]);

  const handleTabsChange = (val) => {
    setIsPaid(val);
  };

  const UserPlansAction = async (params) => {
    try {
      setIsLoading(true);
      const { data } = await clientApi.get("/itineraries/admin/" + userId, {
        params,
      });
      setUserPlans(data?.itineraries);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Tabs currentTab={isPaid} changeTab={handleTabsChange} />
      {isLoading ? (
        <div className="text-center mt-5">
          <CSpinner color="primary" />
        </div>
      ) : (
        <>
          {/* <Suspense fallback={<div>Loading...</div>}> */}
          <PlansComponent plans={userPlans} filterAction={UserPlansAction} />
          {/* </Suspense> */}
        </>
      )}
    </>
  );
};

export default Plans;
