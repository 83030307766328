import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalCount, currentPage, onPageChange }) => {
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    setPageCount(Math.ceil(totalCount / 10));
  }, [totalCount]);

  return (<>
  <div className="justify-content-center w-100 d-flex my-3">
      <ReactPaginate
        breakLabel="..."
        nextLabel=" » "
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="«"
        renderOnZeroPageCount={null}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
      />
      </div>
    </>);
}

Pagination.propTypes = {
  totalCount: PropTypes.number, // total number of records in the table
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;