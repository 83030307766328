import React from "react";
import { CSpinner } from "@coreui/react";
import PropTypes from "prop-types";
import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { formatDate } from "src/utils/common";
import { Link } from "react-router-dom";

const UserList = ({ data, isLoading = false }) => {
  return (
    <CRow>
      {isLoading ? (
        <div className="text-center mt-5 user-wrapper-loader">
          <CSpinner color="primary" />
        </div>
      ) : (
        <CCol xs={12}>
          <CCard className="mb-4">
            <CCardBody>
              <CTable>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                    <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      Registration Date
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      Personality Type
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col">
                      No. of Plans Generated
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {data?.length > 0 ? (
                    data?.map((itm, i) => (
                      <CTableRow key={`user-${i}`}>
                        <CTableDataCell>
                          {itm?.name ? itm?.name : <span> - </span>}
                        </CTableDataCell>
                        <CTableDataCell style={{ maxWidth: "220px" }}>
                          {itm?.email}
                        </CTableDataCell>
                        <CTableDataCell>
                          {formatDate(itm?.registrationDate, "DD/MM/YYYY")}
                        </CTableDataCell>
                        <CTableDataCell>
                          {itm?.personalityType ? (
                            itm?.personalityType
                          ) : (
                            <span> - </span>
                          )}
                        </CTableDataCell>
                        <CTableDataCell>
                          {itm?.planCount > 0 ? (
                            <Link
                              to={`generated-plans`}
                              state={{ userId: itm?.id }}
                            >
                              {itm?.planCount}
                            </Link>
                          ) : (
                            itm?.planCount
                          )}
                        </CTableDataCell>
                      </CTableRow>
                    ))
                  ) : (
                    <CTableRow className="No-data-msg">
                      <CTableDataCell colSpan={5}>
                        No records are available
                      </CTableDataCell>
                    </CTableRow>
                  )}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>
        </CCol>
      )}
    </CRow>
  );
};

export default UserList;

UserList.propTypes = {
  data: PropTypes.array,
};
