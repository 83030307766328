export const LOGIN_SUCCESS = "Logged in successfully";
export const PASSWORD_RESET_SUCCESS = "Your new password has been updated.";
export const OTP_GENERATED_SUCCESS = "OTP has been sent to your email.";
export const OTP_VERIFY_SUCCESS = "OTP verification done.";
export const BREADCRUMBARRAY = [
  {
    home: { path: "", name: "Home" },
    users: { path: "users", name: "Users Management" },
    "personality-quiz": { path: "personality-quiz", name: "Personality Quiz" },
    "travel-quiz": { path: "travel-quiz", name: "Travel Quiz" },
    "generated-plans": {
      path: "generated-plans",
      name: "No. of Plans Generated",
    },
    details: { path: "details", name: "Plan Details" },
  },
];

export const USER_TOKEN = "refresh-token";
export const USER_ID = "user-id";
export const INVITED_ITINERARY_ID = "invited-itinerary-id";
export const INVITED_USER_ID = "invited-user-id";
export const IS_INVITED_USER_TRAVEL_QUIZ_COMPLETED =
  "isInvitedUserTravelQuizCompleted";
export const TOKEN_EXPIRY_DATE = "token-expiry-date";
export const PERSONALITY_ID = "personality-id";
export const SIGN_MESSGAE = "SIGN IN SUCCESS ";
export const TEXT = {
  ON_CREATE_PERSONALITY: "Please create personality first",
  ON_GET_PERSONALITY_DETAILS: "Loading personality...",
  ON_INVITE_FRIEND:
    "Share the invite link with your travel buddies and encourage them to join Ethoventures. After sharing, make sure you all come back to the app to seamlessly continue the onboarding process.",
  ON_SELECT_TRAVELLERS:
    "Embark on a group adventure and unlock the power of shared experiences. Discover new perspectives, forge lifelong friendships, and create stories that will be told for years to come.",
  ON_SELECT_DAYS:
    "Embark on a group adventure and unlock the power of shared experiences. Discover new perspectives, forge lifelong friendships, and create stories that will be told for years to come.",
  ENTER_YOUR_EMAIL: "Enter your email",
  TRAVEL_QUIZ: "Travel Quiz",
  ENTER_YOUR_ANS: "Enter your answer",
  ON_ENTER_LOCATION: "Where are you planing your trip ?",
  AI_ANS_NOTE:
    "More details(Minimum 20 characters) will help us better understand your needs for curating the best possible experience",
  ON_LOAD_ITINERARY: "Please wait till your itineraray loads.",
  ON_USER_ACCESS_TRIPMATE_URL:
    "This link can only be used by the invited users...!",
  BOOK_STAY: "Book your stay",
  BOOK_TABLE: "Book a table",
  BOOK_ACTIVITY: "Book your activity",
};
