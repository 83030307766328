import { CSpinner } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Pagination from "src/components/pagination";
import Filter from "src/components/users/Filter";
import UserList from "src/components/users/UserList";
import clientApi from "src/network";
import { formatDate } from "src/utils/common";

const Users = () => {
  const [userList, setUserList] = useState([]);
  const [isInitialDataLoading, setIsInitialDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterVal, setFilterVal] = useState({
    page: currentPage,
    limit: 10,
    // startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    // endDate: new Date(),
    planCount: "all",
    operation: "",
    q: "",
    isFilter: false,
    sort: "registrationDate",
    sortType: "-1",
  });

  useEffect(() => {
    const timer = setTimeout(() => UserAction(), 500);
    return () => clearTimeout(timer);
  }, [filterVal]);

  const UserAction = async () => {
    if (!(filterVal?.q?.length === 0 || filterVal?.q?.length > 2)) {
      return;
    }
    if (filterVal.isFilter) {
      setIsLoading(true);
    } else {
      setIsInitialDataLoading(true);
    }
    try {
      const postData = { ...filterVal };
      if (!postData?.q) delete postData.q;
      if (postData?.startDate && postData?.endDate) {
        postData.startDate = formatDate(postData.startDate, "YYYY-MM-DD");
        postData.endDate = formatDate(postData.endDate, "YYYY-MM-DD");
      }
      delete postData.isFilter;
      if (postData.planCount === "all") {
        delete postData.planCount;
        delete postData.operation;
      }
      if (postData?.operation === "range") {
        const [from, to] = postData?.planCount?.split("-");
        postData["planCount"] = from;
        postData["planCountTo"] = to;
      }
      const { data } = await clientApi.get("/users", { params: postData });
      setUserList(data?.response);
      setTotalCount(data?.totalCount);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
      setIsInitialDataLoading(false);
    }
  };

  const ExportUserEmails = async () => {
    try {
      const { data } = await clientApi.get("/users/_export-emails");
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "usersEmail.csv";
      document.body.appendChild(anchor);
      anchor.click();
      toast.success("CSV exported succefully.");
    } catch (err) {
      console.log("err", err);
      // toast.error('Something went wrong, please try again!')
    }
  };

  const onPageChange = (event) => {
    const page = event?.selected + 1;
    setCurrentPage(page);
    setFilterVal({ ...filterVal, page, isFilter: true });
  };

  return (
    <>
      {isInitialDataLoading ? (
        <div className="text-center mt-5">
          <CSpinner color="primary" />
        </div>
      ) : (
        <>
          <Filter
            filterVal={filterVal}
            setFilterVal={setFilterVal}
            ExportUserEmails={ExportUserEmails}
            setCurrentPage={setCurrentPage}
          />
          <UserList data={userList} isLoading={isLoading} />
          {totalCount > 0 && (
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              onPageChange={onPageChange}
            />
          )}
        </>
      )}
    </>
  );
};

export default Users;
