import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './views/dashboard/Dashboard';
import DefaultLayout from './layout/DefaultLayout';
import Login from './views/login/Login';
import Users from './views/Users/Users';
import PersonalityQuiz from './views/PersonalityQuiz/PersonalityQuiz';
import AuthProvider from './store/provider/AuthProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './views/forgotPassword/ForgotPassword';
import OTPVerification from './views/forgotPassword/OTPVerification';
import TravelQuiz from './views/TravelQuiz/TravelQuiz';
import Plans from './views/plans/Plans';
import ResetPassword from './views/resetPassword/ResetPassword';
import ItineraryLayout from './views/itinerary/itineraryDetails';
const Page404 = React.lazy(() => import('./views/page404/Page404'))

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )
  

export const router = createBrowserRouter([
        {
            path: "",
            children:[
                {
                    path: '/',
                    element: <Login />,
                },
                {
                    path: '/forgot-password',
                    element: <ForgotPassword />
                },
                {
                    path:'/verify-otp',
                    element: <OTPVerification />
                },
                {
                    path:'/reset-password',
                    element: <ResetPassword />
                },
                {
                    path: '/dashboard',
                    element: <DefaultLayout />,
                    name: 'Dashboard',
                    children: [
                        {
                            path: '',
                            element: <Dashboard/>
                        }
                    ]
                },
                {
                    path: '/users',
                    name: 'Users Management',
                    element: <DefaultLayout />,
                    children: [
                        {
                            path: '',
                            element: <Users />
                        },
                        {
                            path: 'generated-plans',
                            name: 'No. of Plans Generated',
                            // element: <Plans />,
                            children:[
                                {
                                    path: '',
                                    name: 'Plan Details',
                                    element: <Plans />,
                                },
                                {
                                    path: 'details', 
                                    name:'Plan Details',
                                    element: <ItineraryLayout />
                                }
                            ]
                        },

                    ]
                },
                {
                    path: '/personality-quiz',
                    name: 'Personality Quiz',
                    element: <DefaultLayout />,
                    children: [
                        {
                            path: '',
                            element: <PersonalityQuiz />
                        }
                    ]
                },
                {
                    path: '/travel-quiz',
                    name: 'Travel Quiz',
                    element: <DefaultLayout />,
                    children: [
                        {
                            path: '',
                            element: <TravelQuiz />
                        }
                    ]
                },
            ],
            errorElement: <Page404 />
        },
    ]);

    const Routes = () => {
        return (
            <AuthProvider>
              <RouterProvider router={router} fallbackElement={loading} />
              <ToastContainer />
            </AuthProvider>
          );
    }
    
    export default Routes;
