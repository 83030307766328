import { CCard, CCardBody, CCardHeader, CCol, CRow, CSpinner } from "@coreui/react";
import React, { useEffect, useState } from "react";
import clientApi from "src/network";
import QuizAccordion from "src/components/question";

const PersonalityQuiz = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [quizItem, setQuizItem] = useState([]);

  useEffect(() => {
    PersonalityQuizAction();
  }, []);

  const PersonalityQuizAction = async () => {
    try {
      setIsLoading(true);
      const { data } = await clientApi.get("/questions", { params: { category: "personality" } });
      setQuizItem(data?.questions);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-5"><CSpinner color="primary" /></div>
      ) : (
        <CRow>
          <CCol xs={12}>
            <CCard className="mb-4">
              <CCardHeader>Personality Quiz</CCardHeader>
              <CCardBody>{quizItem.length > 0 && <QuizAccordion quizItem={quizItem} />}</CCardBody>
            </CCard>
          </CCol>
        </CRow>
      )}
    </>
  );
};

export default PersonalityQuiz;
