import React from "react";
import Card from "./Card";
import { CRow } from "@coreui/react";
import "./style.scss";

const PlansComponent = ({ plans }) => {
  return (
    <>
      <CRow>
        {plans?.length > 0 ? (
          plans?.map((itinerary, i) => (
            <Card data={itinerary} key={`itinerary-card-${i}`} />
          ))
        ) : (
          <h6 className="text-center">No records found!</h6>
        )}
      </CRow>
    </>
  );
};

export default PlansComponent;
