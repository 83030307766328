import React, { useContext } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import clientApi from "../../network";
import { AuthContext } from "src/store/provider/AuthProvider";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
});

const Login = () => {
  const { AuthUser } = useAuth();
  const { updateAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const postData = values;
      LoginAction(postData);
    },
  });

  const LoginAction = async (param) => {
    try {
      const { data } = await clientApi.post("/auth/login", param);
      if (data) {
        updateAuthUser(data?.response);
        setTimeout(() => navigate("/dashboard"), 3000);
      }
    } catch (err) {
      console.log("err", err);
      // toast.error('Something went wrong, please try again!')
    }
  };

  if (Object.keys(AuthUser).length) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody className="align-items-center d-flex">
                  <CForm method="post" onSubmit={formik.handleSubmit} className="w-100">
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        id="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        invalid={formik.touched.email && formik.errors.email ? true : false}
                      />
                      {formik.touched.email && formik.errors.email ? <CFormFeedback invalid>{formik.errors.email}</CFormFeedback> : null}
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        invalid={formik.touched.password && formik.errors.password ? true : false}
                      />
                      {formik.touched.password && formik.errors.password ? <CFormFeedback invalid>{formik.errors.password}</CFormFeedback> : null}
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          <Link to={"/forgot-password"}>Forgot password?</Link>
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white  py-5 sidebar-banner">
                <CCardBody className="text-center">
                  <div>

                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
