import React, { useEffect, useState } from "react";
import WidgetsDropdown from "../widgets/WidgetsDropdown";
import clientApi from "src/network";
import { CSpinner } from "@coreui/react";

const Dashboard = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    DashboardAction()
  }, [])

  const DashboardAction = async () => {
    try {
      setIsLoading(true);
      const { data } = await clientApi.get("/analytics/admin-details");
      setData(data);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-5"><CSpinner color="primary" /></div>
      ) : (
        data && <WidgetsDropdown data={data} />
      )}
    </>
  );
};

export default Dashboard;
