import React, { useState, useEffect } from "react";
import Loader from "../loader";
import { LoaderStyledWrapper } from "./style";

const ImageComponent = (props) => {
  const [isImageReady, setIsImageReady] = useState(false);
  const { src, alt, className} = props;

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setIsImageReady(true);
    };
    img.src = src;
  }, []);

  if (!isImageReady) {
    return (
      <div>
        <LoaderStyledWrapper>
          <Loader
            size="lg"
            dimension="row"
            className="spinner-loader"
          />
        </LoaderStyledWrapper>
      </div>
    );
  } else {
    return <img src={src} alt={alt} className={className} />;
  }
};

export default ImageComponent;
