import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../../components/common/button";
import './style.scss';


const ItineraryOverview = ({ item }) => {


  return (
    <div>
      <span className="tr-overview-title">Overview</span>
      <span className="tr-overview-desc">{item?.itinerary?.overview}</span>
      
      <Button text={"More detail"} btnClass="CTA-btn tr-btn-mob tr-btn-more" />

      <div className="tr-overview-cards-wrap">
        <Row>
          <Col lg={6} md={6} xs={12} className="mb-3">
            <div className="tr-overview-single-card">
              <span className="tr-count">1</span>
              <span className="tr-card-title">Type of vacation</span>
              <span className="tr-card-desc">
                {item?.itinerary?.vacation_type}
              </span>
            </div>
          </Col>
          <Col lg={6} md={6} xs={12} className="mb-3">
            <div className="tr-overview-single-card">
              <span className="tr-count">2</span>
              <span className="tr-card-title">Travel Personality</span>
              <span className="tr-card-desc">
                {item?.itinerary?.personality}
              </span>
            </div>
          </Col>
          <Col lg={6} md={6} xs={12} className="mb-3">
            <div className="tr-overview-single-card">
              <span className="tr-count">3</span>
              <span className="tr-card-title">Travel Season</span>
              <span className="tr-card-desc">
                {item?.itinerary?.vacation_season}
              </span>
            </div>
          </Col>
          <Col lg={6} md={6} xs={12} className="mb-3">
            <div className="tr-overview-single-card">
              <span className="tr-count">4</span>
              <span className="tr-card-title">Full Trip Budget</span>
              <span className="tr-card-desc">
                {item?.metaData[0]?.total_budget}
              </span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ItineraryOverview;
