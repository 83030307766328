import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser } from "@coreui/icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clientApi from "src/network";
import { useAuth } from "src/hooks/useAuth";
import { OTP_GENERATED_SUCCESS } from "src/Constant";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email is required"),
});
const ForgotPassword = () => {
  const { AuthUser } = useAuth();
  if (Object.keys(AuthUser).length) {
    return <Navigate to={"/dashboard"} />;
  }
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const postData = values;
      ForgotPasswordAction(postData);
    },
  });

  const ForgotPasswordAction = async (param) => {
    try {
      const { data } = await clientApi.post("/auth/forgot-password", param);

      if (data) {
        toast.success(OTP_GENERATED_SUCCESS);
        setTimeout(
          () =>
            navigate("/verify-otp", {
              state: param,
              replace: true
            }),
          3500
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>

              <CCard className="p-4">
                <CCardBody className="align-items-center d-flex">
                  <CForm method="post" onSubmit={formik.handleSubmit} className="w-100">
                    <h1>Forgot Password</h1>
                    <p className="text-medium-emphasis">Retrive your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        id="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik?.values?.email}
                        invalid={formik?.touched?.email && formik?.errors?.email ? true : false}
                      />
                      {formik?.touched?.email && formik?.errors?.email ? <CFormFeedback invalid>{formik?.errors?.email}</CFormFeedback> : null}
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Submit
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-end">
                        <CButton color="link" className="px-0">
                          <Link to={"/"}>Back</Link>
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>

              <CCard className="text-white py-5 sidebar-banner">
                <CCardBody className="text-center">
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPassword;
