import { CAvatar } from '@coreui/react'
import React from 'react'

const TeamMetes = ({data}) => {
    return (<>
    { data?.map((dt, i) => <TeamMetesImg src={dt?.picture} key={`teamMatesAvatar-${i}`}/>)}
    </>)
}

const TeamMetesImg = ({src}) => {
    return(
        <CAvatar  src={src} />
    );
}
export default TeamMetes