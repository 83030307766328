import React, { useState, useEffect } from 'react';
import { CButton } from '@coreui/react';
import clientApi from 'src/network';
import { toast } from 'react-toastify';
import { OTP_GENERATED_SUCCESS } from 'src/Constant';

function ResendOTPButton({email}) {
  const [countdown, setCountdown] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);


  const startCountdown = () => {
    setIsResendDisabled(true); 
    setCountdown(60);

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setIsResendDisabled(false);
    }, 60000);
  };

  useEffect(() => {
    startCountdown();
  }, []);

  const ResendOTPAction = async () => {
    try {
      const { data } = await clientApi.post("/auth/forgot-password", {email});
      if (data) {
        startCountdown();
        toast.success(OTP_GENERATED_SUCCESS);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className='text-center mt-3'>
      <CButton type='button' color="primary" className="px-4" onClick={ResendOTPAction} disabled={isResendDisabled}>
        Resend OTP
      </CButton>
      {isResendDisabled && <p>Resend OTP in {countdown} seconds</p>}
    </div>
  );
}

export default ResendOTPButton;
