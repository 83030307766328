import { CFormInput } from '@coreui/react';
import React, { useEffect, useRef } from 'react';

const OTPInput = ({ otpDigits, onDigitChange, onBackspace, autoFocus, index }) => {
  const inputRef = useRef(null);

    useEffect(() => {
        if (autoFocus) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace' && otpDigits[index] === '') {
          e.preventDefault()
          onBackspace(index - 1);
        }
    };

    return (
        <CFormInput
            type="number"
            className="otp-input"
            value={otpDigits[index]}
            onChange={(e) => onDigitChange(index, e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus={autoFocus}
            ref={inputRef}
            maxLength={1}
        />
    );
};

export default OTPInput;
